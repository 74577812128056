<template>
  <div id="info-box">
    <v-card height="100%"
      :href="this.data.url"
      :img="this.background"
      style="background-position: center left;"
      target="_blank"
      :hover="hasURL" >
      <div class="bg">
        <div class="blue-title">
          <h4>{{this.unescapeHTML(this.data.title)}}</h4>
        </div>

        <div class="image" v-if="this.data.image">
          <img :src="$imgAddr + this.data.image" width="200px" height="auto">
        </div>

        <div :style="textStyle" v-html="this.unescapeHTML(this.data.message)"></div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'info-box',
  components: {
  },
  props: {
    data: Object
  },
  methods: {
    unescapeHTML: function(unsafe) {
      return unsafe
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'")
    }
  },
  computed: {
    textStyle: function() {
      return {
        width: "100%",
        textAlign: "left",
        color: this.data.messagecolor || "white",
        padding: "5px",
        textShadow: "1px 1px 1px #000000"
      }
    },
    display: function() {
      return {
        backgroundSize: "cover",
        zIndex: "-1",
        backgroundImage: `url("${this.background}")`
      }
    },
    hasURL: function() {
      return this.data.url.length > 0
    }
  },
  data () {
    return {
      background: `https://www.allieddistributors.ca/Images/${this.data.background}`
    }
  }
}
</script>

<style scoped>
  #info-box {
    width: 100%;
    height: 100%;
    min-height: 200px;
    text-align: left;
  }

  .image{
    vertical-align: top;
    max-width: 200px;
    float: left;
    margin-left: 0px;
    margin-right: 10px;
    text-align: center;
    padding-left: 5px;
    padding-top: 5px;
  }

  img {
    object-position: 50% 50%;
    object-fit: cover;
  }

  .bg {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .yellow-title {
    padding-left: 5px;
    font-size: 22pt;
    overflow: hidden;
  }
</style>

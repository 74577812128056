<template>
  <div id="specials"  v-if="this.specials.length>0">
    <div class="yellow-title banner">
      <h1>In Store Specials</h1>
    </div>

    <div class="page">
      <v-slide-group
        v-model="model"
        class="pa-4"
        mobile-breakpoint="20"
        show-arrows>
        <v-slide-item
          v-for="(special, i) in specials"
          :key="i"
          v-slot:default="{ active, toggle }">
          <special @click="toggle" :data="special" v-on="$listeners"/>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import Special from './special'
import axios from 'axios'

export default {
  name: 'specials',
  components: {
    Special
  },
  data () {
    return {
      page: 1,
      model: null,
      specials: []
    }
  },
  methods: {
    getSpecials: function() {
      axios
        .get(this.$api + "main.php?cmd=getSpecials")
        .then(response => {
            this.specials = response.data.result
        })
    },
    showSpecial: function() {
    }
  },
  mounted () {
    this.getSpecials()
  }
}
</script>

<style scoped>
  #specials {
    margin-top: 5px;
    width: 100%;
  }

  .banner {
    width: 100%;
    padding-left: 5px;
  }

  .page {
    width: 100%;
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

Vue.prototype.$imgAddr = "https://www.allieddistributors.ca/Images/"
Vue.prototype.$flyerAddr = "https://www.allieddistributors.ca/flyers/"
Vue.prototype.$bulletinAddr = "https://www.allieddistributors.ca/bulletins/"
Vue.prototype.$carouselAddr = "https://www.allieddistributors.ca/Images/marquis/"
Vue.prototype.$api = "https://api.allieddistributors.ca/"

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="bottom">
    <v-row
      no-gutters>
      <v-col>
        <div
          class="section">
          <h4>Contact Us:</h4>
          Phone: <a href="tel:+1780-352-7161">(780) 352-7161</a><br>
          Toll Free: <a href="tel:+1888-373-3303">(888) 373-3303</a><br>
          Fax: (780) 352-8680<br>
          <a href="mailto:info@allieddistributors.ca">info@allieddistributors.ca</a><br>
        </div>
      </v-col>

      <v-col>
        <div
          class="section">
          <h4>Visit Us:</h4>
          4613 49th Street<br>
          Wetaskiwin, AB<br>
          Canada, T9A 2E9<br>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
}
</script>

<style scoped>
  .bottom {
    color: white;
    width: 100%;
    background-color: #004B96;
  }

  .section {
    padding: 10px;
    font-size: 10pt;
  }

  a:link {
    color: #ccffff;
    text-decoration: none;
  }

  /* visited link */
  a:visited {
    color: #ccffff;
    text-decoration: none;
  }

  /* mouse over link */
  a:hover {
    color: white;
    text-decoration: none;
  }

  /* selected link */
  a:active {
    color: #ffffcc;
    text-decoration: none;
  }
</style>

<template>
  <div id="statement" v-if="this.title">
    <div class="yellow-title banner" v-if="this.title">
      <h1>{{this.title}}</h1>
    </div>
    <div class="content">
      <div v-if="this.description" v-html="this.description" class="text"></div><br>
      <img :src="image" v-if="this.image" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'statement',
  components: {
  },
  data () {
    return {
      title: '',
      titleColor: 'black',
      image: '',
      description: '',
      descriptionColor: 'black'
    }
  },
  methods: {
    getStatement: function() {
      axios
        .get(this.$api + "main.php?cmd=getStatement")
        .then(response => {
            this.title = this.unescapeHTML(response.data.title)
            this.titleColor = response.data.titlecolor
            this.description = this.unescapeHTML(response.data.description)
            this.descriptionColor = response.data.descriptioncolor
            this.image = this.$imgAddr + response.data.image
        })
    },
    unescapeHTML: function(unsafe) {
      return unsafe
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'")
},

  },
  mounted () {
    this.getStatement()
  }
}
</script>

<style scoped>
  #statement {
    width: 100%;
  }

  .text {
    margin: 5px;
    background-color: #f8f8f8;
  }

  .banner {
    padding-left: 5px;
  }

  .content {
    text-align: center;
    font-size: 10pt;
  }
</style>

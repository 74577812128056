<template>
  <div id="app">
    <v-app>
      <div
        class="blockout"
        v-if="specialInfo.display"
        @click="clearSpecial">
        <div class="special">
          <h3 class="yellow-title">{{specialInfo.title}}</h3>
          <div class="specprice">
            {{specialInfo.price}}
          </div>
          <img class="image" :src="specialInfo.image" height="200px"/>
          <div
            class="description"
            v-html="unescapeHTML(specialInfo.description)">
          </div>

        </div>
      </div>

      <div
        class="content">
        <div
          class="banner">
          <banner />
        </div>
        <div
          class="main">
            <carousel />
            <under-construction />
            <specials v-on:show-special="showSpecial"/>
            <statement />
            <information />
        </div>

        <div
          class="bottom">
          <bottom />
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import UnderConstruction from './components/under_construction'
import Banner from './components/banner'
import Bottom from './components/bottom'
import Carousel from './components/carousel'
import Specials from './components/specials'
import Statement from './components/statement'
import Information from './components/information'

export default {
  name: 'App',
  components: {
    UnderConstruction,
    Banner,
    Bottom,
    Carousel,
    Specials,
    Statement,
    Information
  },
  data () {
    return {
      specialInfo: {
        display: false,
        price: "",
        title: "",
        description: "",
        image: "",
        begins: "",
        expires: ""
      }
    }
  },
  methods: {
    unescapeHTML: function(unsafe) {
      if (unsafe){
        return unsafe
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, "\"")
          .replace(/&#039;/g, "'");
      }

      return unsafe
    },
    showSpecial: function(special) {
      this.specialInfo.display = true
      this.specialInfo.title = special.title
      this.specialInfo.description = special.description
      this.specialInfo.price = special.price
      this.specialInfo.image = this.$imgAddr + special.image
      this.specialInfo.begins = special.begins
      this.specialInfo.expires = special.expires

      console.log(this.specialInfo)
    },
    clearSpecial: function() {
      this.specialInfo.display = false
      this.specialInfo.title = ""
      this.specialInfo.description = ""
      this.specialInfo.price = ""
      this.specialInfo.image = ""
      this.specialInfo.begins = ""
      this.specialInfo.expires = ""
    }
  }
}
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    width: 100%;
    height: 100%;
    padding: 0px;
  }

  html, body {
    margin:0;
    height:100%;
    min-height:100%;
  }

  .content {
    padding: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .banner {
    flex-grow: 0;
    width: 100%;
  }

  .bottom {
    flex-grow: 0;
    width: 100%;
  }

  .main {
    flex-grow: 1;
    overflow-x: auto;
    border-top: 1px solid #ddd;
  }

  .yellow-title {
    background-color: #ffc72c;
    color: black;
  }

  .blue-title {
    background-color: #004B96;
    color: white;
    padding: 5px;
  }

  .blockout {
    z-index: 1001;
    position: absolute;
    top: 0px;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    text-align: center;
    color: black;
  }

  .image {
    max-width: 100%;
  }

  .special {
    position: absolute;
    top: 10%;
    z-index: 1000;
    background: white;
    height: 80%;
    overflow: auto;
  }

  .description {
    font-size: 8pt;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    border: 1px solid #aaa;
    background-color: #eee;
    border-radius: 5px;
  }

  .specprice {
    background-color: rgba(255,255,255,0.8);
    border: 1px solid #bbb;
    border-radius: 3px;
    padding: 3px;
    font-size: 12pt;
    font-weight: 600;
    text-shadow: 2px 2px silver;
    color: red;
    position: absolute;
    top: 190px;
    left: 10px;
  }

</style>

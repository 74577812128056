<template>
  <div id="banner">
    <div class="title">
      <img
        class="bannerimg"
        src="../assets/banner_mobile.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'banner',
  components: {
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
  #banner {
    width: 100%;
  }

  .title {
    width: 100%;
  }

  .bannerimg {
    width: 100%;
  }
</style>

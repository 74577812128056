<template>
  <div id="information" v-if="this.infoitems">
    <div class="yellow-title banner">
      <h1>Useful Information</h1>
    </div>

    <div class="content">
      <div class="grid">
        <div class="grid__item" v-for="(info, index) in infoitems">
          <info-box :data="info" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoBox from './info_box'
import axios from 'axios'

export default {
  name: 'information',
  components: {
    InfoBox
  },
  data () {
    return {
      infoitems: [],
    }
  },
  methods: {
    getInformation: function() {
      axios
        .get(this.$api + "main.php?cmd=getInformation")
        .then(response => {
          this.infoitems = response.data.results
        })
    }
  },
  mounted () {
    this.getInformation()
  }
}
</script>

<style scoped>
  #information {
    width: 100%;
    margin-top: 10px;
  }

  .content {
    text-align: center;
    font-size: 16pt;
    min-height: 200px;
    margin-top: 5px;
    padding: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(250px, auto);
    grid-gap: 1rem;
  }

  .grid__item {

  }
</style>

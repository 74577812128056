<template>
  <div id="carousel">
    <v-carousel
      v-model="model"
      height="150px"
      width="100%"
      :show-arrows="true"
      :cycle="true"
      :hide-delimiters="true">
      <v-carousel-item
        v-for="(image, i) in this.images"
        :key="i">
        <v-sheet
          height="100%"
          width="100%"
          tile>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <div class="display-3 full">
              <img :src="$carouselAddr + image.image">
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'carousel',
  components: {
  },
  data () {
    return {
      images: [],
      model: 0
    }
  },
  methods: {
    getImages: function() {
      axios
        .get(this.$api + "main.php?cmd=getImages")
        .then(response => {
          this.images = response.data.images
        })
    }
  },
  mounted () {
    this.getImages()
  }
}
</script>

<style scoped>
#carousel {
  margin-top: 5px;
  width: 100%;
}

img {
  height: 100%;
  width: 100%;
  object-position: 50% 50%;
  object-fit: cover;
}

.full {
  width: 100%;
  height: 100%;
}
</style>

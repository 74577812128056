<template>
  <div class="construction">
    <img
      class="constructimage"
      src="../assets/under_construction.jpg"/>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .construction {
    text-align: center;
    overflow-x: auto;
  }

  .constructimage {
    max-width: 700px;
    width: 100%;
  }

  body {
    background-color: black;
  }

  h2 {
  }
</style>

<template>
  <div id="special">
    <v-card
      hover
      ripple
      height="180px"
      width="214px"
      :img="this.image"
      @click="toggleSpecial()">
      <div class='information'>
        <h2 v-if="this.data.title.length > 0">{{this.unescapeHTML(this.data.title)}}</h2>
        <span class="price">
          {{this.data.price}}
        </span>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'special',
  components: {
  },
  props: {
    data: Object
  },
  data () {
    return {
      image: '',
      active: false
    }
  },
  methods: {
    toggleSpecial: function() {
      this.$emit('show-special', this.data)
    },
    unescapeHTML: function(unsafe) {
      return unsafe
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'")
    }
  },
  mounted () {
    this.image = this.$imgAddr + this.data.image
  }
}
</script>

<style scoped>
  #special {
    margin: 5px;
  }

  .information {
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-height: 70px;
    text-align: center;
    font-size: 6pt;
    text-align: center;
    background-color: rgba(255,255,255,0.8);
    overflow: hidden;
  }

  .price {
    font-size: 12pt;
    font-weight: 600;
    text-shadow: 2px 2px silver;
    color: red;
  }
</style>
